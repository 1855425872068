import { Alert, Space } from 'antd';
import { SearchI, SearchResponseI } from '../common/searchHooks';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../common/theme';
import { rbapi } from '../../common/api/api';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { TourRefs } from '../views/SearchPage';

const StyledAlert = styled(Alert)`
  font-size: 110%;
  background-color: ${theme.blueLighter};
  border-color: ${theme.blue};

  a {
    color: ${theme.blue} !important;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function InfoMessage({
  searchResponse,
  currentSearch,
  setCurrentSearch,
  refs,
}: {
  searchResponse?: SearchResponseI;
  currentSearch: SearchI;
  setCurrentSearch: React.Dispatch<React.SetStateAction<SearchI>>;
  refs: TourRefs;
}) {
  const screens = useBreakpoint();
  if (!searchResponse) return;

  const msgs = [];

  if (searchResponse.count > 0) {
    if (currentSearch.filters.length == 0) {
      msgs.push(
        <span key="found">
          <strong>{searchResponse.count}</strong> resultaten gevonden.
        </span>,
      );
    } else if (currentSearch.filters.length == 1) {
      msgs.push(
        <span key="found">
          <strong>{searchResponse.count}</strong> resultaten gevonden in "{currentSearch.filters[0]}".
        </span>,
      );
    } else {
      msgs.push(
        <span key="found">
          <strong>{searchResponse.count}</strong> resultaten gevonden in {currentSearch.filters.length} categoriën.
        </span>,
      );
    }
  }

  const hasAuthHeader = Object.keys(rbapi.defaults.headers.common).includes('Authorization');
  if (!hasAuthHeader) {
    msgs.push(
      <span key="no-auth">
        <strong>
          Tip: <a href="/login">Log in</a> om in de ledendatabase te zoeken.
        </strong>
      </span>,
    );
  }

  if (!currentSearch.showOld) {
    msgs.push(
      <span key="show-old" ref={refs.showallRef}>
        Resultaten ouder dan 1 jaar worden verborgen.{' '}
        <a
          onClick={_e =>
            setCurrentSearch({
              ...currentSearch,
              showOld: true,
            })
          }
        >
          Alles tonen?
        </a>
      </span>,
    );
  } else {
    msgs.push(
      <span key="hide-old">
        <a
          onClick={_e =>
            setCurrentSearch({
              ...currentSearch,
              showOld: false,
            })
          }
        >
          Oude resultaten verbergen.
        </a>
      </span>,
    );
  }

  if (!msgs.length) return;
  return (
    <StyledAlert
      message={
        <Space direction={screens.md ? 'horizontal' : 'vertical'} style={{ gap: '.25rem' }}>
          {msgs}
        </Space>
      }
      type="info"
    />
  );
}
