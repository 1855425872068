import { Form, Input, message, Modal } from 'antd';
import { theme } from '../common/theme';
import { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { SearchI } from '../common/searchHooks';
import { LogSnagProvider, useLogSnag } from '@logsnag/react';
import { AppProps } from '../../common/types/AppProps';
import { TourRefs } from '../views/SearchPage';

interface FormProps {
  query: string;
  intention: string;
  feedback: string;
}

export default function FeedbackForm({ appProps, currentSearch, refs }: { appProps: AppProps; currentSearch: SearchI; refs: TourRefs }) {
  const [form] = Form.useForm<FormProps>();
  const [isOpen, setIsOpen] = useState(false);
  const { setUserId, track, identify } = useLogSnag();

  const openModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsOpen(true);
  };

  useEffect(() => {
    form.setFieldsValue({ query: currentSearch.q });
  }, [currentSearch]);

  const onFinish = (values: FormProps) => {
    if (appProps.userId) setUserId(appProps.userId);

    track({
      user_id: appProps.userId,
      channel: 'rb-feedback',
      event: 'User has provided feedback',
      icon: '💬',
      tags: {
        query: values.query,
        intention: values.intention,
        feedback: values.feedback,
      },
    });

    if (appProps.userEmail && appProps.userId) {
      identify({
        user_id: appProps.userId,
        properties: {
          email: appProps.userEmail,
        },
      });
    }

    form.resetFields();
    setIsOpen(false);
    message.success('Bedankt voor uw feedback. We nemen het mee in de verdere ontwikkeling van de zoekfunctie.');
  };

  return (
    <div>
      <LogSnagProvider token="7449b845c7cab56b64f2eb4843300a24" project="studio-slash">
        <Modal open={isOpen} title="Deel feedback mbt de zoekfunctie" cancelText="Sluiten" okText="Verstuur feedback" onCancel={() => setIsOpen(false)} onOk={() => form.submit()}>
          <Form form={form} layout="vertical" style={{ marginTop: 20 }} onFinish={onFinish}>
            <Form.Item label="Huidige zoekterm" name="query">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Waar zocht u naar?" name="intention">
              <TextArea placeholder="Deel een omschrijving naar wat u zocht." />
            </Form.Item>
            <Form.Item label="Overige feedback" name="feedback">
              <TextArea placeholder="Deel overige feedback: hoe kan deze zoekfunctie beter?" />
            </Form.Item>
          </Form>
        </Modal>
      </LogSnagProvider>
      <p style={{ color: theme.gray, margin: 0 }}>
        Deze zoekfunctie is nog in ontwikkeling.{' '}
        <a href="#" onClick={openModal} ref={refs.feedbackRef}>
          Niet gevonden wat je zocht? Geef ons hier feedback.
        </a>
      </p>
    </div>
  );
}
