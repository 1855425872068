import { List } from 'antd';
import { SearchResponseI, SearchResultI } from '../common/searchHooks';
import Result from './Result';
import styled from 'styled-components';
import { TourRefs } from '../views/SearchPage';

const StyledList = styled(List)`
  .ant-pagination {
    margin-top: 4rem;
  }
`;

export default function Results({ isLoading, searchResponse, refs }: { isLoading: boolean; searchResponse?: SearchResponseI; refs: TourRefs }) {
  return (
    <StyledList
      loading={isLoading}
      dataSource={searchResponse?.results}
      itemLayout="vertical"
      renderItem={(result, i) => <Result result={result as SearchResultI} i={i} refs={refs} />}
      //...
    />
  );
}
