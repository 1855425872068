import { TourProps } from "antd"
import { TourRefs } from "../views/SearchPage"

const commonProps = {
    nextButtonProps: { children: 'Volgende' },
    prevButtonProps: { children: 'Vorige' },
}

export const createTourSteps = (refs: TourRefs, q: string): TourProps['steps'] => {
    return [
        {
            title: 'Nieuwe RB zoekfunctie',
            description: `Met de input van leden hebben wij deze zoekfunctie ontwikkeld. Je kunt nu snel en gemakkelijk op modellen, dossiers, curssusen, nieuws en de rest van de website zoeken. Zoek bijvoorbeeld op "${q}".`,
            target: () => refs.searchRef.current,
            type: 'primary',
            ...commonProps,
        },
        {
            title: 'Verbeterde weergave',
            description: `De weergave van de zoekresultaten is verbeterd om snel de juiste informatie te kunnen vinden. Bij modellen kun je van de live preview gebruik maken om te controleren of dit het bestand is wat je zoekt.`,
            target: () => refs.resultsRef.current,
            placement: 'top',
            ...commonProps,
        },
        {
            title: 'Filters',
            description: 'Gebruik deze filters om je zoekopdracht te beperken. We hebben de filters uitgekleed en deze op basis van de meest voorkomende zoekopdrachten gemaakt.',
            target: () => refs.filtersRef.current,
            ...commonProps,
        },
        {
            title: 'Datumbereik',
            description: 'We tonen standaard resultaten ouder dan 1 jaar niet om te voorkomen dat je door oude dossiers wordt afgeleid. Je kunt deze instelling hier wijzigen als je op zoek bent naar oudere resultaten.',
            target: () => refs.showallRef.current,
            ...commonProps,
        },
        {
            title: 'Volgende pagina\'s',
            description: 'Vergeet niet dat zoekresultaten ook op volgende pagina\'s te vinden zijn.',
            target: () => refs.paginationRef.current,
            ...commonProps,
        },
        {
            title: 'Feedback & suggesties',
            description: 'Niet gevonden wat je zocht? We blijven de zoekfunctie graag verbeteren. Laat het ons weten!',
            target: () => refs.feedbackRef.current,
            ...commonProps,
            nextButtonProps: { children: 'Tour sluiten' },
        },
    ]
}