import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';

const el = document.getElementById('rb-search');

if (el) {
  createRoot(el).render(
    <StrictMode>
      <App {...el.dataset} />
    </StrictMode>,
  );
}
