import { Pagination as AntdPagination, PaginationProps } from 'antd';
import { SearchI, SearchResponseI } from '../common/searchHooks';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

export default function Pagination({
  searchResponse,
  currentSearch,
  setCurrentSearch,
}: {
  searchResponse?: SearchResponseI;
  currentSearch: SearchI;
  setCurrentSearch: React.Dispatch<React.SetStateAction<SearchI>>;
}) {
  const screens = useBreakpoint();
  if (!searchResponse) return;

  const onChange: PaginationProps['onChange'] = page => {
    setCurrentSearch({
      ...currentSearch,
      page,
    });
  };

  return (
    <AntdPagination
      size={screens.md ? 'default' : 'small'}
      showPrevNextJumpers={true}
      current={currentSearch.page}
      total={searchResponse.count}
      onChange={onChange}
      showSizeChanger={false}
    />
  );
}
