import { Form, FormProps, Space, Tour } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SearchI, useSearch } from '../common/searchHooks';
import InfoMessage from '../components/InfoMessage';
import Filters from '../components/Filters';
import SearchInput from '../components/SearchInput';
import Results from '../components/Results';
import Pagination from '../components/Pagination';
import PreviewContainer from '../components/PreviewContainer';
import FeedbackForm from '../components/FeedbackForm';
import { AppProps } from '../../common/types/AppProps';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import { createTourSteps } from '../config/TourSteps';

export interface TourRefs {
  searchRef: React.MutableRefObject<null>;
  filtersRef: React.MutableRefObject<null>;
  showallRef: React.MutableRefObject<null>;
  feedbackRef: React.MutableRefObject<null>;
  paginationRef: React.MutableRefObject<null>;
  resultsRef: React.MutableRefObject<null>;
}

const Container = styled.div<{ $md: boolean | undefined; $lg: boolean | undefined }>`
  min-height: 50rem;
  max-width: 1000px;

  margin-top: -7rem;
  ${props => props.$md && `margin-top: -5.5rem;`}
  ${props => props.$lg && `margin-top: -6.5rem;`}

  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export interface SearchFormI {
  q: string;
  filters: string[];
}

export default function SearchPage({ appProps }: { appProps: AppProps }) {
  // screen size
  const screens = useBreakpoint();

  // tour
  const refs: TourRefs = {
    searchRef: useRef(null),
    filtersRef: useRef(null),
    showallRef: useRef(null),
    feedbackRef: useRef(null),
    paginationRef: useRef(null),
    resultsRef: useRef(null),
  };
  const [tourOpen, setTourOpen] = useState(false);

  // state to pass to search
  const [currentSearch, setCurrentSearch] = useState<SearchI>({
    q: '',
    page: 1,
    filters: [],
    showOld: false,
  });

  // search hook
  const { data, isLoading } = useSearch(currentSearch);

  // form
  const [form] = Form.useForm();
  const onFinish: FormProps<SearchFormI>['onFinish'] = formValues => {
    if (formValues.q) {
      setCurrentSearch({
        ...currentSearch,
        q: formValues.q,
        filters: formValues.filters || [],
        page: 1,
      }); // trigger search

      window.history.pushState('', '', `?q=${encodeURIComponent(formValues.q)}`); // set url
    }
  };

  // on init, set params from url
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const q = urlParams.get('q');
    if (q) {
      form.setFieldValue('q', q); // update form
      setCurrentSearch({
        ...currentSearch,
        q: q,
      }); // trigger search
    }

    const tour = urlParams.get('tour');
    if (tour && tour != '0') {
      setTourOpen(true);
    }
  }, []);

  // on search change, scroll to top:
  useEffect(() => {
    window.scrollTo({
      top: 160,
      left: 0,
      behavior: 'instant',
    });
  }, [data]);

  return (
    <>
      <PreviewContainer />

      <Container $md={screens.md} $lg={screens.lg}>
        {/* search form, messages and filters */}
        <Form form={form} onFinish={onFinish}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <SearchInput isLoading={isLoading && currentSearch.q != ''} onSearch={form.submit} refs={refs} />
            <Filters onChange={form.submit} refs={refs} />
            <InfoMessage searchResponse={data} currentSearch={currentSearch} setCurrentSearch={setCurrentSearch} refs={refs} />
            {currentSearch.q != '' && <FeedbackForm appProps={appProps} currentSearch={currentSearch} refs={refs} />}
          </Space>
        </Form>

        {/* top pagination (for tour) */}
        <div ref={refs.paginationRef}>
          <Pagination searchResponse={data} currentSearch={currentSearch} setCurrentSearch={setCurrentSearch} />
        </div>

        {/* search results */}
        <Results isLoading={isLoading && currentSearch.q != ''} searchResponse={data} refs={refs} />

        {/* bottom pagination */}
        <Pagination searchResponse={data} currentSearch={currentSearch} setCurrentSearch={setCurrentSearch} />
      </Container>

      {data && data.count > 0 && <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={createTourSteps(refs, currentSearch.q)} placement="top" />}
    </>
  );
}
