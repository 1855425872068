import { Checkbox, Form } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import styled from 'styled-components';
import { TourRefs } from '../views/SearchPage';

const StyledFormItem = styled(Form.Item)<{ $onMobile?: boolean }>`
  margin-bottom: 0;
  padding-bottom: 1rem;
  background-color: ${props => (props.$onMobile ? ' #f5f5f5' : '0')};
  padding-left: ${props => (props.$onMobile ? '1rem' : '0')} !important;
  padding-right: ${props => (props.$onMobile ? '1rem' : '0')} !important;
`;

const StyledCheckbox = styled(Checkbox)<{ $bgColor?: string }>`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${props => props.$bgColor};
  }
`;

const filters = {
  algemeen: { label: 'Algemeen', color: '#6f63c5' },
  modellen: { label: 'Modellen', color: '#559449' },
  vakinformatie: { label: 'Vakinformatie', color: '#a37832' },
  onderwijs: { label: 'Onderwijs', color: '#c44c44' },
  nieuws: { label: 'Nieuws', color: '#b94a9d' },
  // geen: { label: 'Geen label', color: 'red' },
};

export default function Filters({ onChange, refs }: { onChange: () => void; refs: TourRefs }) {
  const screens = useBreakpoint();

  return (
    <div ref={refs.filtersRef} style={{ width: 'fit-content' }}>
      <StyledFormItem $onMobile={!screens.md} name="filters" valuePropName="checked" label={null}>
        <Checkbox.Group>
          {Object.entries(filters).map(([k, v]) => {
            return (
              <StyledCheckbox key={k} value={k} $bgColor={v.color} onChange={onChange}>
                {v.label}
              </StyledCheckbox>
            );
          })}
        </Checkbox.Group>
      </StyledFormItem>
    </div>
  );
}
