import { message, Space } from 'antd';
import { SearchResultI } from '../common/searchHooks';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { wpapi } from '../../common/api/api';
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import useEmitter from '../../common/hooks/emitter';

export default function Preview({ result }: { result: SearchResultI }) {
  const { emit } = useEmitter();
  const [isLoading, setIsLoading] = useState(false);

  const showPreview = async () => {
    try {
      setIsLoading(true);
      const res: AxiosResponse = await wpapi.get('', {
        params: {
          action: 'rb_search_index_preview', // TODO: fetch from PHP
          id: result.id,
        },
      });
      const { preview_url, download_url }: { preview_url?: string; download_url: string } = res.data;

      if (!download_url) {
        message.error('Geen preview beschikbaar voor dit zoekresultaat');
        return;
      }

      if (preview_url) {
        emit('open-preview', { preview_url, download_url });
      } else {
        // @ts-expect-error can't read window open?
        window.open(download_url, '_blank').focus();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <a onClick={_e => showPreview()}>
      <Space>
        {isLoading && <LoadingOutlined />}
        {!isLoading && <DownloadOutlined />}
        Preview
      </Space>
    </a>
  );
}
