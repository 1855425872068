import { Form, Input } from 'antd';
import styled from 'styled-components';
import { TourRefs } from '../views/SearchPage';

const { Search } = Input;

const StyledFormItem = styled(Form.Item)`
  background-color: #d9d9d9;
  width: 100%;
  padding: 1rem;
  margin-bottom: 0;
`;

export default function SearchInput({ isLoading, onSearch, refs }: { isLoading: boolean; onSearch: () => void; refs: TourRefs }) {
  return (
    <div ref={refs.searchRef}>
      <StyledFormItem name="q">
        <Search
          placeholder="Zoeken op modellen, dossiers, curssusen, nieuws, forum posts..."
          enterButton={!isLoading && 'Zoeken'}
          size="large"
          onSearch={onSearch}
          loading={isLoading}
        />
      </StyledFormItem>
    </div>
  );
}
