import { useQuery } from "@tanstack/react-query";
import { rbapi } from "../../common/api/api";

export interface SearchI {
    q: string;
    page: number;
    filters: string[];
    showOld: boolean;
}

export interface SearchResultI {
    id: string;
    type: string;
    guid: string;
    title: string;
    kenmerk: string;
    summary: string;
    meta: string[];
    tag: string;
    color: string;
    highlight: boolean;
    url: string;
    _debug?: any;
}

export interface SearchResponseI {
    took: number;
    count: number;
    post_types: {
        name: string;
        count: number;
    }[];
    public_private: {
        name: string;
        count: number;
    }[];
    results: SearchResultI[];

    // added by front-end:
    totalPages: number;
}

export interface ApiError {
    msg: string;
    status: number;
}

export const useSearch = ({ q, page, filters, showOld }: SearchI) => useQuery({
    queryKey: ['search', q, page, showOld, filters],
    queryFn: async (): Promise<SearchResponseI> => {
        // Bail if empty query
        if (!q || q.trim() == '') throw new Error('Oh no!')

        // Craft params
        const params = new URLSearchParams([
            ['q', q],
            ['p', page.toString()],
            ['showOld', (showOld ? '1' : '0')],
            ...filters.map(f => ['c', f]),
            // // ...
        ]);

        // Track to umami
        if (window.umami) {
            window.umami.track('search', {
                q,
                p: page.toString(),
                showOld: (showOld ? '1' : '0'),
                filters,
            });

            // window.umami.track(`user searched on: "${q}"`, {});
        }

        // Response
        const hasAuthHeader = Object.keys(rbapi.defaults.headers.common).includes('Authorization')

        const endpoint = hasAuthHeader ? 'search' : 'search-public';
        const url = `${endpoint}?${params.toString()}`;

        const { data }: { data: SearchResponseI } = await rbapi.get(url);
        return data;
    },
});