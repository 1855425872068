import styled from 'styled-components';
import Preview from './Preview';
import { List, Space, Tag } from 'antd';
import { SearchResultI } from '../common/searchHooks';
import { theme } from '../common/theme';
import { CalendarOutlined, PaperClipOutlined, UserOutlined } from '@ant-design/icons';
import { hasPreview } from '../common/preview';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { TourRefs } from '../views/SearchPage';

const IconText = ({ text }: { text: string }) => (
  <Space>
    {text.toLowerCase().includes('geleden') && <CalendarOutlined />}
    {text.toLowerCase().includes('modeltype') && <PaperClipOutlined />}
    {text.toLowerCase().includes('exclusief') && <UserOutlined />}
    {text}
  </Space>
);

const StyledListItem = styled(List.Item)<{ $color?: string; $padded?: boolean }>`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: ${props => (props.$padded ? '1rem' : '0')} !important;
  padding-right: ${props => (props.$padded ? '1rem' : '0')} !important;

  &:nth-child(even) {
    background: #f8f8f8;
  }

  &.highlight {
    background: #f8f8f8;
    border: 1px #d9d9d9 solid;
    padding: 1rem 0rem 1rem 1.2rem;
    padding-bottom: 1.5rem !important;
    margin-bottom: 2rem;

    .ant-list-item-meta-title {
      // color: ${props => props.$color} !important;
    }
  }

  &:hover {
    opacity: 0.9;

    .ant-list-item-meta-title {
      color: ${theme.primary} !important;
    }

    .ant-list-item-meta-description {
      color: rgba(0, 0, 0, 0.7) !important;
    }
  }
`;

const StyledTag = styled(Tag)`
  text-transform: capitalize;
`;

export default function Result({ result, refs, i }: { result: SearchResultI; refs: TourRefs; i: number }) {
  const screens = useBreakpoint();

  // Craft action tags:
  // Add badge (tag)
  const actions = [<StyledTag color={result.color}>{result.tag}</StyledTag>];
  // Add meta data from search
  actions.push(...result.meta.filter(m => m != '').map(m => <IconText text={m} />));
  // Add preview (if applicable)
  if (hasPreview(result) && screens.md) actions.push(<Preview result={result} />);

  return (
    <StyledListItem
      $padded={screens.md}
      $color={result.color}
      className={result.highlight ? `highlight` : ``}
      key={result.title}
      actions={actions}
      ref={i == 0 ? refs.resultsRef : null}
    >
      <a href={result.url}>
        <List.Item.Meta title={result.title} description={result.summary} />
      </a>
    </StyledListItem>
  );
}
