import './App.scss';
import { Alert, ConfigProvider } from 'antd';
import SearchPage from './views/SearchPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { theme } from './common/theme';
import { AppProps } from '../common/types/AppProps';
import { rbapi, wpapi } from '../common/api/api';
import { useState } from 'react';

function App(initialAppProps: AppProps) {
  const [appProps] = useState(initialAppProps);
  const queryClient = new QueryClient();

  // Set up rbapi
  if (!appProps.apiUrl) return <Alert type="error" message={`No rbapi url set`} />;
  rbapi.defaults.baseURL = appProps.apiUrl;
  if (appProps.apiToken) rbapi.defaults.headers.common['Authorization'] = `Bearer ${appProps.apiToken}`;

  // Set up wpapi
  if (!appProps.wpAjaxUrl) return <Alert type="error" message={`No wpajaxurl url set`} />;
  wpapi.defaults.baseURL = appProps.wpAjaxUrl;

  // Find and hide word-lid
  const wordLid = document.getElementById('word-lid');
  if (wordLid) wordLid.style.display = 'none';

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: theme.primary,
          borderRadius: 0,
        },
      }}
      renderEmpty={() => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{ height: 30 }}>
            <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
          </svg>
          <p>Geen zoekresultaten om weer te geven</p>
        </div>
      )}
    >
      <QueryClientProvider client={queryClient}>
        <SearchPage appProps={appProps} />
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
