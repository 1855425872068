import { Button, Drawer, Flex } from 'antd';
import { useEffect, useState } from 'react';
import useEmitter from '../../common/hooks/emitter';

export default function PreviewContainer() {
  const { on, off } = useEmitter();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [previewUrl, setPreviewUrl] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    on('open-preview', async ({ preview_url, download_url }) => {
      setLoading(true);
      setOpen(true);
      setDownloadUrl(download_url);
      // load document
      setPreviewUrl(preview_url);
      // wait a sec
      await new Promise(resolve => setTimeout(resolve, 2000));
      // stop loading
      setLoading(false);
      // track to umami
      if (window.umami) {
        window.umami.track('used-preview', {});
      }
    });

    return () => {
      // on dismount
      off('open-preview');
    };
  }, []);

  return (
    <Drawer
      title={
        <Flex justify="space-between">
          Preview{' '}
          <Button href={downloadUrl} type="primary" onClick={onClose}>
            <span style={{ color: 'white' }}>Download bestand</span>
          </Button>
        </Flex>
      }
      onClose={onClose}
      open={open}
      width="40rem"
      style={{ padding: 0 }}
      loading={loading}
    >
      <iframe src={previewUrl} style={{ width: '100%', border: 0, height: '100%' }} key={previewUrl} />
    </Drawer>
  );
}
